import React from 'react';

import CenterBlockMiddleInfo1 from "./CenterBlockMiddleInfo1";
import Fetch from "../../common/Fetch";

export default () =>
    <div className="footer-block">
        <h4>
            <Fetch bundleKey="Hiplines_phone.Title" />
        </h4>
        <CenterBlockMiddleInfo1/>
    </div>
