import React from 'react';
import {faFacebook, faInstagram, faTelegram, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default () =>
    <ul className="social-bock">
        <li className="social-bock__item"><a href="https://www.facebook.com/bnsaspr/" target="_blank" rel="noopener noreferrer" title="facebook"><FontAwesomeIcon icon={faFacebook} color={'#FFF'} size={'2x'}/></a></li>
        <li className="social-bock__item"><a href="https://www.instagram.com/statgov.kz" target="_blank" rel="noopener noreferrer" title="instagram"><FontAwesomeIcon icon={faInstagram} color={'#FFF'} size={'2x'} /></a></li>
        <li className="social-bock__item"><a href="https://t.me/stat_gov_kz_official" target="_blank" rel="noopener noreferrer" title="instagram"><FontAwesomeIcon icon={faTelegram} color={'#FFF'} size={'2x'} /></a></li>
        <li className="social-bock__item"><a href="https://www.youtube.com/channel/UCktTEsE4VDBGHg2oAzMI8xA" target="_blank" rel="noopener noreferrer" title="instagram"><FontAwesomeIcon icon={faYoutubeSquare} color={'#FFF'} size={'2x'} /></a></li>
    </ul>
