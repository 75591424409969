import React from 'react';

import AboutFirst from './AboutFirst';
// import AboutSecond from './AboutSecond';

export default () => (
    <div className="submenu-in">
        <AboutFirst/>
        {/*<AboutSecond/>*/}
    </div>
);
