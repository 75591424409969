import React from 'react';

import CopyrightTop from './CopyrightTop';
import CopyrightBottom from './CopyrightBottom';

export default () =>
    <div className="footer-copyright">
        <CopyrightTop/>
        <CopyrightBottom/>
    </div>
