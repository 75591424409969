import React from 'react';

import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';
import HeaderMiddle from "./HeaderMiddle";

export default () => (
    <div className="header-row">
        <HeaderLeft/>
        <HeaderMiddle/>
        <HeaderRight/>
    </div>
);
