import React from 'react';

import HeaderLogo from './HeaderLogo';
import HeaderTitle from './HeaderTitle';

export default () => (
    <div className="header-left">
        <HeaderLogo/>
        <HeaderTitle/>
    </div>
);
