import React from 'react';

import Fetch from "../../common/Fetch";

export default () =>
    <div className="footer-block-body">
        <Fetch bundleKey="main.ContactPressService.str1" />
        <br/>
        <Fetch bundleKey="main.ContactPressService.str2" />
        <br/>
        <Fetch bundleKey="main.ContactPressService.str3" />
        <br/>
        <Fetch bundleKey="main.ContactPressService.str4" />
    </div>
