import React from 'react';
import {Link} from 'react-router-dom';
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
    siteMap: {
        id: "footer.siteMap",
        defaultMessage: "Сайт картасы"
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <div className="footer-block">
            <h4>
                <Link to="/misc/map">
                    {formatMessage(messages.siteMap)}
                </Link>
            </h4>
        </div>
    )
};
