import React, {useContext} from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Link} from 'react-router-dom';

import {MenuContext} from "../../../contexts/menu/MenuContext";

const messages = defineMessages({
    byIndustry: {
        id: "header.byIndustry",
        defaultMessage: "Өнеркәсіп бойынша"
    },
    publications: {
        id: "header.publications",
        defaultMessage: "Жарияланымдар"
    },

});

const Links = () => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const { dispatch } = useContext(MenuContext);

    function onMenuClickHandle() {
        dispatch({ type: "setActiveMenuItem", payload: "null" })
    }

    return (
        <ul>
            <li>
                <Link to="/official/industry" onClick={onMenuClickHandle}>{formatMessage(messages.byIndustry)}</Link>
            </li>
            <li>
                <Link to="/edition/publication" onClick={onMenuClickHandle}>{formatMessage(messages.publications)}</Link>
            </li>
        </ul>
    )
};

export default () => (
    <div className="submenu-column">
        <Links/>
    </div>
);
