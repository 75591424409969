import React, {Fragment} from 'react';
import ScrollUpButton from "react-scroll-up-button";
import {Helmet} from 'react-helmet';
import {defineMessages, useIntl} from "react-intl";

import {MenuContextProvider} from "./contexts/menu/MenuContext"

import Header from './components/header/Header';
import Navigation from "./components/navigation/Navigation";
import Main from "./components/main/Main";
import Links from './components/links/Links';
import Premier from './components/premier/Premier';
import Carousel from './components/carousel/Carousel';
import Footer from './components/footer/Footer';

const messages = defineMessages({
    ministryName: {
        id: 'header.ministryName',
        defaultMessage: 'Қазақстан Республикасы'
    },
    countryName: {
        id: 'header.countryName',
        defaultMessage: 'Ұлттық экономика министрлігі'
    },
    companyName: {
        id: 'header.companyName',
        defaultMessage: 'Статистика комитеті'
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <Fragment>
            <Helmet>
                <title>{formatMessage(messages.companyName)}</title>
                <meta name="description"
                      content={
                          formatMessage(messages.ministryName) + ' ' +
                          formatMessage(messages.countryName) + ' ' +
                          formatMessage(messages.companyName)
                      }
                />
            </Helmet>

            <Header/>

            <MenuContextProvider>
                <Navigation/>
            </MenuContextProvider>

            <Main/>
            <Links/>
            <Premier/>
            <Carousel/>
            <Footer/>

            <ScrollUpButton/>
        </Fragment>
    )
};
