import React, {useReducer} from 'react';

const initialState = {
    activeMenuItem: null
};

export const MenuContext = React.createContext(initialState);

export const MenuContextProvider = ({ children }) => {

    const reducer = (state, action) => {
        switch (action.type) {

            case "setActiveMenuItem" :
                return {
                    ...state,
                    activeMenuItem: action.payload
                };

            default:
                return {...state};
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <MenuContext.Provider value={{ ...state, dispatch }}>
            {children}
        </MenuContext.Provider>
    )
};