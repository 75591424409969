import React from "react"
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
    closeButtonMsg: {
        id: "button.close",
        defaultMessage: "Жiберу"
    },
});

export default (props) => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const {message, onClickHandler} = props;

    return(
        <div className="modalDialog-overlay">
            <div className="modalDialog-table">
                <div className="modalDialog-cell">
                    <div className="modalDialog-modal">
                        <p className="modalDialog-modal--message"><strong>{message}</strong></p>

                        <div className="modalDialog-action">

                            <button className="button"
                                    onClick={(e)=>onClickHandler(e)}
                                    type="submit">
                                {formatMessage(messages.closeButtonMsg)}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
