import React from 'react';

import SearchFirst from './SearchFirst';
import SearchSecond from './SearchSecond';

export default () => (
    <div className="submenu-in submenu-search-in">
        <SearchFirst/>
        <SearchSecond/>
    </div>
);
