import React from 'react';

import RightBlockTopInfo from "./RightBlockTopInfo";

import Fetch from "../../common/Fetch";

export default () =>
    <div className="footer-block">
        <h4>
            <Fetch bundleKey="main.ContactCallcentre.str3" />
        </h4>
        <RightBlockTopInfo/>
    </div>
