import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import Analytics from 'react-router-ga';

import * as serviceWorker from './serviceWorker';

import {GeneralContextProvider} from "./contexts/general/GeneralContext";

import IntlWrapper from "./IntlWrapper";

ReactDOM.render((
    <BrowserRouter>
        <Analytics id="UA-45806082-1" debug>
            <GeneralContextProvider>
                <IntlWrapper/>
            </GeneralContextProvider>
        </Analytics>
    </BrowserRouter>
    ), document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
