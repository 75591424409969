import React from 'react';

import HeaderTopMenu from './HeaderTopMenu';
import HeaderLangMenu from './HeaderLangMenu'
import HeaderBottomMenu from "./HeaderBottomMenu";

export default () => (
    <div className="header-right">
        <HeaderTopMenu/>
        <HeaderLangMenu/>
        <HeaderBottomMenu />
    </div>
);
