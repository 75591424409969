import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {defineMessages, useIntl} from "react-intl";

import {GeneralContext} from "../../contexts/general/GeneralContext";

const messages = defineMessages({
    ministerBlog: {
        id: "main.ministerBlog",
        defaultMessage: "ҚР Ұлттық экономика министрінің блогы"
    },
    presidentMessage: {
        id: "main.presidentMessage",
        defaultMessage: "Қазақстан Республикасы Президентінің Жолдаулары"
    },
    kz2050: {
        id: "main.kz2050",
        defaultMessage: "Қазақстан 2050"
    },
    govSymbols: {
        id: "main.govSymbols",
        defaultMessage: "Қазақстан Республикасының мемлекеттік рәміздері"
    },
    newTabMsg: {
        id: "main.newTabMsg",
        defaultMessage: "Бұл сілтеме жаңа терезеде (салымында) ашылады. Жалғастырғыңыз келе ме?"
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const { generalConfig, locale, conf: { url: { prmLang } } } = useContext(GeneralContext);
    const urlMinisterBlog = (generalConfig ? generalConfig.ministerBlogURL : '') + '?' + prmLang + locale;

    return (
        <div className="container">
            <ul>
                <li>
                    <div className="href-button">
                        <button onClick={() =>
                            window.confirm(formatMessage(messages.newTabMsg)) &&
                            window.open(urlMinisterBlog)
                        }>
                            <span>{formatMessage(messages.ministerBlog)}</span>
                        </button>
                    </div>
                    {/*<a href={urlMinisterBlog} target="_blank" rel="noopener noreferrer">
                        {formatMessage(messages.ministerBlog)}
                    </a>*/}
                </li>
                <li>
                    <Link to='/general/message'>{formatMessage(messages.presidentMessage)}</Link>
                </li>
                <li>
                    <Link to='/general/current'>{formatMessage(messages.kz2050)}</Link>
                </li>
                <li>
                    <Link to='/general/symbol'>{formatMessage(messages.govSymbols)}</Link>
                </li>
            </ul>
        </div>
    );
};
