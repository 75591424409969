import React, {useContext} from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Link} from 'react-router-dom';
import {MenuContext} from "../../../contexts/menu/MenuContext";

const messages = defineMessages({
    depList: {
        id: "header.depList",
        defaultMessage: "Облыстардың, Алматы және Астана қалаларының статистика бөлімдерінің тізімі"
    }
});

const Links = () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const { dispatch } = useContext(MenuContext);

    function onMenuClickHandle() {
        dispatch({ type: "setActiveMenuItem", payload: "null" })
    }

    return (
        <ul>
            <li>
                <Link to="/region" onClick={onMenuClickHandle}>{formatMessage(messages.depList)}</Link>
            </li>
        </ul>
    )
};

export default () => (
    <div className="submenu-column">
        <Links/>
    </div>
);
