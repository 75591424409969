import React, {useContext,  useState} from "react"
import {defineMessages, useIntl} from "react-intl";
import {GeneralContext} from "../../contexts/general/GeneralContext";
import Axios from "axios";
import Spinner from "../common/Spinner";
import InformationDialog from "./InformationDialog";

const messages = defineMessages({
    sendButtonMsg: {
        id: "button.send",
        defaultMessage: "Жiберу"
    },
    cancelButtonMsg: {
        id: "button.cancel",
        defaultMessage: "Жiберу"
    },
    mistake: {
        id: "mistake.mistake",
        defaultMessage: "Қате"
    },
    comment: {
        id: "mistake.comment",
        defaultMessage: "Пікір"
    },
    commentPlaceholder: {
        id: "mistake.commentPlaceholder",
        defaultMessage: "Пікір енгізіңіз"
    },
    findMistake: {
        id: "mistake.findMistake",
        defaultMessage: "Қатені байқадыңыз ба?"
    },
    selectText: {
        id: "mistake.selectText",
        defaultMessage: "Тышқанмен белгілеп, Ctrl+Enter басыңыз"
    },
    sendMessage: {
        id: "mistake.sendMessage",
        defaultMessage: "Сіздің хабарламаңыз жіберілді"
    },
    messageTooLong: {
        id: "mistake.messageTooLong",
        defaultMessage: "Вы выбрали слишком большой объем текста"
    },

});

const addMistake = (locale, selectionText, selectionURL, commentText, setIsCtrlEnter, setIsInformationDialog, setIsLoading) => {
    const url = '/api/mistake/save';

    setIsLoading(true);

    Axios.post(url, `descrip=${commentText}&lang=${locale}&text=${selectionText}&url=${selectionURL}`)
        .then(() => {
            setIsLoading(false);
            setIsCtrlEnter(false);
            // const body = document.getElementsByTagName("body")[0];
            // body.classList.remove('body-overflow--hidden');
            setIsInformationDialog(true);
        })
        .catch((error) => {
            console.error("ADD mistake Request: " + url, error)
        });
};

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const { locale } = useContext(GeneralContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isCtrlEnter, setIsCtrlEnter] = useState(false);
    const [selectionText, setSelectionText] = useState("");
    const [selectionURL, setSelectionURL] = useState("");
    const [commentText, setCommentText] = useState("");

    const [isInformationDialog, setIsInformationDialog] = useState(false);
    const [isInformationDialogTooLong, setIsInformationDialogTooLong] = useState(false);

    function ctrlEnter (event){
        if((event.ctrlKey) && ((event.keyCode === 0xA)||(event.keyCode === 0xD)))
        {
            const selection = window.getSelection().toString();
            // const body = document.getElementsByTagName("body")[0];
            // body.classList.add('body-overflow--hidden');

            setSelectionText(selection);
            if (selection.length > 5 && selection.length < 500)
                setIsCtrlEnter(true);
            else if (selection.length < 5)
                {
                    setIsCtrlEnter(false);
                    setIsInformationDialogTooLong(false);
                }
            else
                setIsInformationDialogTooLong(true);

            setSelectionURL(window.location.href)
        }
    }

    function onCancelInformationDialogHandle (event){
        event.preventDefault();
        // const body = document.getElementsByTagName("body")[0];
        // body.classList.remove('body-overflow--hidden');
        setIsInformationDialog(false);
        setIsInformationDialogTooLong(false);
    }

    function onCancelHandle (event){
        event.preventDefault();
        // const body = document.getElementsByTagName("body")[0];
        // body.classList.remove('body-overflow--hidden');
        setIsCtrlEnter(false);
    }

    function onSaveHandle (event){
        event.preventDefault();
        addMistake(locale, selectionText, selectionURL, commentText, setIsCtrlEnter, setIsInformationDialog, setIsLoading)
    }

    document.onkeydown = ctrlEnter;

    return(
        <React.Fragment>

            {isInformationDialog && <InformationDialog message={formatMessage(messages.sendMessage)} onClickHandler={onCancelInformationDialogHandle}/>}
            {isInformationDialogTooLong && <InformationDialog message={formatMessage(messages.messageTooLong)} onClickHandler={onCancelInformationDialogHandle}/>}

            {
                isCtrlEnter &&
                <div className="modalDialog-overlay">
                    <div className="modalDialog-table">
                        <div className="modalDialog-cell">
                            <div className="modalDialog-modal">
                                <strong>{formatMessage(messages.mistake)}:</strong>
                                <p>{selectionText}</p>
                                <br />
                                <label>{formatMessage(messages.comment)}:</label>
                                <input
                                    placeholder={formatMessage(messages.commentPlaceholder)}
                                    type="text"
                                    maxLength="150"
                                    onChange={(event) => setCommentText(event.target.value)}
                                />

                                <div className="modalDialog-action">
                                    <button className="button"
                                            onClick={(e)=>onSaveHandle(e)}
                                            disabled={isLoading}
                                            type="submit">
                                        {isLoading ?
                                            <Spinner size='lg' spinning='spinning' /> :
                                            formatMessage(messages.sendButtonMsg)
                                        }
                                    </button>

                                    <button className="button"
                                            onClick={(e)=>onCancelHandle(e)}
                                            disabled={isLoading}
                                            type="submit">
                                        {formatMessage(messages.cancelButtonMsg)}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <p>
                {formatMessage(messages.findMistake)}<br />
                {formatMessage(messages.selectText)}
                <br />
                <br />
            </p>
        </React.Fragment>
    )
}
