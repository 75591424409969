import React from 'react';

import FooterRow from './FooterRow';
import FooterCopyright from './copyright/FooterCopyright';
import FooterCountZero from "./FooterCountZero";
// import FooterYandexMetrika from "./FooterYandexMetrika";

export default () =>
    <div className="container">
        <FooterRow/>
        <FooterCopyright/>
        <FooterCountZero/>
        {/*<FooterYandexMetrika/>*/}
    </div>
