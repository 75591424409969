import React, {lazy, Suspense, useContext, useEffect, useState} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';

import Axios from "axios/index";

import Spinner from "../common/Spinner";

import {GeneralContext} from "../../contexts/general/GeneralContext";

const Home = lazy(() => import('./Home'));
const Feeds = lazy(() => import('./feeds/Feeds'));
const Counter = lazy(() => import('./counter/Counter'));
const Regulation = lazy(() => import('./about/regulation/Regulation'));
const Important = lazy(() => import('./important/Important'));
const General = lazy(() => import('./general/General'));
const Miscs = lazy(() => import('./miscs/Miscs'));
const Respondent = lazy(() => import('./respondent/Respondent'));
const Region2 = lazy(() => import('./region/Region2'));
const Industry = lazy(() => import('./official/industry/Industry'));
const Search = lazy(() => import('./search/Search'));
const Inf = lazy(() => import('./official/inf/Inf'));
const Cluster = lazy(() => import('./official/cluster/Cluster'));
const Census = lazy(() => import('./official/census/Census'));
const Monitoring = lazy(() => import('./official/monitoring/Monitoring'));
const Publication = lazy(() => import('./official/publication/Publication'));
const Interesting = lazy(() => import('./official/interesting/Interesting'));
const JurList = lazy(() => import('./official/jurlist/JurList'));
const Indicator = lazy(() => import('./official/indicator/Indicator'));
const Feedback = lazy(() => import('./feedback/Feedback'));
const Quiz = lazy(() => import ('./quiz/Quiz'));
const EcologicIndicators = lazy(() => import('./ecologicIndicators/EcologicIndicators'));
const SustainableDevelopmentGoals = lazy(() => import('./official/sustainableDevelopmentGoals/SustainableDevelopmentGoals'));
const Dynamic = lazy(() => import('./dynamic/dynamic'));
const EconomicQuality = lazy(() => import('./economicQuality/EconomicQuality'));
const PublicationAdd = lazy(() => import('./publicationAdd/PublicationAdd'));
const ForResearch = lazy(() => import('./forResearch/ForResearch'));
const UsefulLinks = lazy(() => import('./usefulLinks/UsefulLinks'));
const UnitedNation = lazy(() => import('./unitedNation/UnitedNation'));
const OfficialIntro = lazy(() => import('./official/officialIntro/OfficialIntro'));

const getFirstIndustry = ({ itemStatisticId, rootStatisticId, locale, setFirstIndustry }) => {
    const urlKlazz = "api/klazz/" + itemStatisticId +"/" + rootStatisticId + "/" + locale;

    Axios.get(urlKlazz)
        .then(response => {
            if (response && response.data && response.data.success) {

                let industries = response.data.list.filter(item => {
                    return !(item.endDate && new Date(item.endDate) < new Date());
                });

                if (industries && industries[0]) {
                    setFirstIndustry(industries[0].code);
                }
            }
        })
        .catch((error) => {
            console.error("MainContainer: " + urlKlazz, error)
        })
};

const MainContainer = props => {
    const { history: { location } } = props;

    const { generalConfig, locale } = useContext(GeneralContext);

    const { clsStatisticVersionId, clsStatisticParentId } = generalConfig;

    const itemStatisticId = clsStatisticVersionId || 0;
    const rootStatisticId = clsStatisticParentId || 0;

    const [firstIndustry, setFirstIndustry] = useState("");

    useEffect(() => {
        getFirstIndustry({ itemStatisticId, rootStatisticId, locale, setFirstIndustry });
    }, [itemStatisticId, rootStatisticId, locale]);

    const pathName = location ? location.pathname : null;
    const pathArr = pathName ? pathName.split("/") : [];
    const currentIndustry = pathArr.length > 0 && pathArr[1]  && pathArr[1] === "official"  && pathArr[2]  &&
        pathArr[2] === "industry" && pathArr[3] ? Number(pathArr[3]) : null;

    if (currentIndustry && currentIndustry !== firstIndustry) {
        setFirstIndustry(currentIndustry);
    }


    // const regionCode  = pathArr.length > 0  && pathArr[1]  && pathArr[1] === "region" && pathArr[2] ? Number(pathArr[2]) : null

    window.scrollTo(0,0);

    return (
        <div className="container">
            <Suspense fallback={<Spinner size='3x' color="#10a4fb" spinning='spinning'/>}>
                <Switch>
                    <Route exact path='/' render={(props) => (
                        <Home {...props}/>
                    )}/>
                    <Route path='/announce' render={(props) => (
                        <Feeds {...props}/>
                    )}/>
                    {/*<Route exact path='/news' render={(props) => (*/}
                    {/*    <Feeds {...props}/>*/}
                    {/*)}/>*/}
                    {/*<Route path='/news/:docId' render={(props) => (*/}
                    {/*    <Feeds {...props}/>*/}
                    {/*)}/>*/}
                    {/*<Route path='/press-release' render={(props) => (*/}
                    {/*    <Feeds {...props}/>*/}
                    {/*)}/>*/}
                    <Route path='/counter' render={(props) => (
                        <Counter {...props}/>
                    )}/>


                    <Route path='/jur-search/bin' render={(props) => (
                        <JurList {...props}/>
                    )}/>
                    <Route path='/jur-search/filter' render={(props) => (
                        <JurList {...props}/>
                    )}/>
                    <Route path='/jur-search/status' render={(props) => (
                        <JurList {...props}/>
                    )}/>
                    <Route path='/jur-search/bin/:binIin' render={(props) => (
                        <JurList {...props}/>
                        )} />

                        <Route path='/juridical/request_counter' render={(props) => (
                            <JurList {...props}/>
                        )}/>

                    <Route path='/juridical/list' render={(props) => (
                        <JurList {...props}/>
                    )}/>
                    <Route path='/juridical/api' render={(props) => (
                        <JurList {...props}/>
                    )}/>
                    <Route path='/juridical/instruction' render={(props) => (
                        <JurList {...props}/>
                    )}/>

                    <Route path='/feedback/:sectionId' render={(props) => (
                        <Feedback {...props}/>
                    )}/>

                    <Route path='/about/contact' render={(props) => (
                        <Regulation {...props}/>
                    )}/>

                    <Route path='/important/classifier' render={(props) => (
                        <Important {...props}/>
                    )}/>
                    <Route path='/important/classifier/statistical' render={(props) => (
                        <Important {...props}/>
                    )}/>
                    <Route path='/important/classifier/adapter' render={(props) => (
                        <Important {...props}/>
                    )}/>
                    <Route path='/important/classifier/approval' render={(props) => (
                        <Important {...props}/>
                    )}/>
                    <Route path='/important/classifier/archive' render={(props) => (
                        <Important {...props}/>
                    )}/>
                    <Route path='/important/methodology' render={(props) => (
                        <Important {...props}/>
                    )}/>
                    <Route path='/important/simple' render={(props) => (
                        <Important {...props}/>
                    )}/>
                    <Route path='/general/message' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/052018' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/102018' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2017' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2015' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/112014' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/012014' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/122012' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/012012' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2011' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2010' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2009' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2008' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2007' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2006' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2005' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2004' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2003' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2002' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2001' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/2000' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/1999' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/1998' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/message/1997' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/current' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/symbol' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/symbol/flag' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/symbol/emblem' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/general/symbol/anthem' render={(props) => (
                        <General {...props}/>
                    )}/>
                    <Route path='/misc/link' render={(props) => (
                        <Miscs {...props}/>
                    )}/>
                    <Route path='/misc/map' render={(props) => (
                        <Miscs {...props}/>
                    )}/>
                    <Route path='/misc/subscribe' render={(props) => (
                        <Miscs {...props}/>
                    )}/>
                    <Route path='/misc/unsubscribe' render={(props) => (
                        <Miscs {...props}/>
                    )}/>

                    <Route path='/quiz/questionnaire/:id' render={(props) => (
                        <Quiz {...props}/>
                    )}/>
                    <Route path='/quiz/price' render={(props) => (
                        <Quiz {...props}/>
                    )}/>
                    <Route path='/quiz/product' render={(props) => (
                        <Quiz {...props}/>
                    )}/>
                    <Route path='/quiz' render={(props) => (
                        <Quiz {...props} />
                    )}/>

                    <Route path='/respondent/for_respondents' render={(props) => (
                        <Respondent {...props}/>
                    )}/>
                    <Route path='/respondent/form' render={(props) => (
                        <Respondent {...props}/>
                    )}/>
                    <Route path='/respondent/archive' render={(props) => (
                        <Respondent {...props}/>
                    )}/>
                    <Route path='/respondent/catalog' render={(props) => (
                        <Respondent {...props}/>
                    )}/>
                    <Route path='/respondent/software' render={(props) => (
                        <Respondent {...props}/>
                    )}/>
                    <Route path='/region/list' render={(props) => (
                        <Region2 {...props}/>
                    )}/>
                    <Route path='/region/:itemId' render={(props) => (
                        <Region2 {...props}/>
                    )}/>
                    <Route path='/region/:itemId/statinfo' render={(props) => (
                        <Region2 {...props}/>
                    )}/>
                    <Route path='/region/:itemId/dynamic' render={(props) => (
                        <Region2 {...props}/>
                    )}/>
                    <Route path='/region/:itemId/social' render={(props) => (
                        <Region2 {...props}/>
                    )}/>
                    <Route path='/region/:itemId/simple' render={(props) => (
                        <Region2 {...props}/>
                    )}/>
                    <Route path='/region/:itemId/feedback' render={(props) => (
                        <Region2 {...props}/>
                    )}/>
                    <Route path='/region/:itemId/statistical_information/industry' render={(props) => (
                        <Region2 {...props}/>
                    )}/>
                    <Route path='/region/:itemId/statistical_information/industry/:industryId' render={(props) => (
                        <Region2 {...props}/>
                    )}/>
                    <Route path='/region/:itemId/statistical_information/publication' render={(props) => (
                        <Region2 {...props}/>
                    )}/>

                    <Route path='/search' render={(props) => (
                        <Search {...props}/>
                    )}/>
                    <Route path='/search/:searchTxt' render={(props) => (
                        <Search {...props}/>
                    )}/>
                    <Route path='/search/item/:docId' render={(props) => (
                        <Search {...props}/>
                    )}/>

                    <Route path='/for_users/dynamic' render={(props) => (
                        <Dynamic {...props}/>
                    )}/>
                    <Route path='/for_users/economic_quality' render={(props) => (
                        <EconomicQuality {...props}/>
                    )}/>

                    <Route path='/for_users/standard/report' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/report/response' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/report/observance' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/report/estimation' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/national' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/calendar' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/real' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/real/account' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/real/industrial' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/real/employment' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/real/unemployment' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/real/salary' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/real/consumer' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/real/producer' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/fiscal' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/fiscal/central' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/fiscal/general' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/fiscal/debt' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/finance' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/finance/deposit' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/finance/central' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/external' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/external/balance' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/external/reserve' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/external/trade' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/external/investment' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/external/debt' render={(props) => (
                        <Inf {...props}/>
                    )}/>
                    <Route path='/for_users/standard/metadata/external/statistics_of_interest_rates' render={(props) => (
                        <Inf {...props}/>
                    )}/>

                    <Route path='/for_users/cluster/intro' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/chapter1' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/chapter2' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/chapter3' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/chapter4' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/chapter5' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/chapter6' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/chapter7' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/chapter8' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/chapter9' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/users/cluster/chapter10' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/users/cluster/chapter11' render={(props) => (
                        <Cluster {...props}/>
                    )}/>
                    <Route path='/for_users/cluster/video' render={(props) => (
                        <Cluster {...props}/>
                    )}/>

                    <Route path='/for_users/national/2020' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/2009' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/2009/general' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/2009/region' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/2009/info' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/1999' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/agriculture2006_2007' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/agriculture2006_2007/general' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/agriculture2006_2007/methodology' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/agriculture2006_2007/methodology/guide' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/agriculture2006_2007/regulation' render={(props) => (
                        <Census {...props}/>
                    )}/>
                    <Route path='/for_users/national/agriculture2006_2007/digest' render={(props) => (
                        <Census {...props}/>
                    )}/>

                    {/*<Route path='/for_users/economic/mne' render={(props) => (*/}
                    {/*    <Monitoring {...props}/>*/}
                    {/*)}/>*/}
                    {/*<Route path='/for_users/economic/mik' render={(props) => (*/}
                    {/*    <Monitoring {...props}/>*/}
                    {/*)}/>*/}
                    {/*<Route path='/for_users/economic/food' render={(props) => (*/}
                    {/*    <Monitoring {...props}/>*/}
                    {/*)}/>*/}
                    {/*<Route path='/for_users/economic/quasi' render={(props) => (*/}
                    {/*    <Monitoring {...props}/>*/}
                    {/*)}/>*/}
                    <Route path='/for_users/economic/monitoring_agglomerations' render={(props) => (
                        <Monitoring {...props}/>
                    )}/>

                    {/*START SustainableDevelopmentGoals*/}
                    <Route path='/for_users/sustainable_development_goals' render={(props) => (
                        <SustainableDevelopmentGoals {...props}/>
                    )}/>
                    {/*END SustainableDevelopmentGoals*/}

                    {/*<Route path='/for_users/indicator' render={(props) => (*/}
                    {/*    <Indicator {...props}/>*/}
                    {/*)}/>*/}
                    {/*<Route path='/for_users/archive' render={(props) => (*/}
                    {/*    <Indicator {...props}/>*/}
                    {/*)}/>*/}

                    {/*START dynamic ecologic indicators*/}
                    <Route path='/for_users/ecologic_indicators/:indicator' render={(props) => (
                        <EcologicIndicators {...props}/>
                    )}/>
                    {/*END dynamic ecologic indicators*/}


                    <Route exact  path='/official/industry' render={(props) => (
                        <OfficialIntro {...props}/>
                    )}/>

                    <Route path='/official/industry/:industryId/statistic/:infoTypeId' render={(props) => (
                        <Industry {...props}/>
                    )}/>
                    <Route path='/official/industry/:industryId/statistic/:infoTypeId/:groupId' render={(props) => (
                        <Industry {...props}/>
                    )}/>
                    <Route path='/official/industry/:industryId/methodology' render={(props) => (
                        <Industry {...props}/>
                    )}/>
                    <Route path='/official/industry/:industryId/publication' render={(props) => (
                        <Industry {...props}/>
                    )}/>
                    <Route path='/official/industry/:industryId/contact' render={(props) => (
                        <Industry {...props}/>
                    )}/>

                    <Route path='/publication/add' render={(props) => (
                        <PublicationAdd {...props}/>
                    )}/>

                    <Route path='/for_research' render={(props) => (
                        <ForResearch {...props}/>
                    )}/>

                    <Route path='/useful_links' render={(props) => (
                        <UsefulLinks {...props}/>
                    )}/>

                    <Route path='/for_users/united_nation' render={(props) => (
                        <UnitedNation {...props}/>
                    )}/>

                    <Route path='/edition/publication/collection' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/periodical' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/quarter' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/month' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/booklet' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/summary' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/1' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/2' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/3' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/4' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/5' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/6' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/7' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/8' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/9' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/10' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/11' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/12' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/13' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/14' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/15' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/16' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/17' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/18' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/19' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/20' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/21' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/22' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/23' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/24' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/25' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/26' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/edition/publication/material/region' render={(props) => (
                        <Publication {...props}/>
                    )}/>
                    <Route path='/itis/interesting/name' render={(props) => (
                        <Interesting {...props}/>
                    )}/>
                    <Route path='/itis/interesting/likeme' render={(props) => (
                        <Interesting {...props}/>
                    )}/>
                    <Route path='/itis/interesting/know' render={(props) => (
                        <Interesting {...props}/>
                    )}/>
                    <Route path='/itis/interesting/whom' render={(props) => (
                        <Interesting {...props}/>
                    )}/>

                    <Redirect from='/about' to='/about/contact'/>

                    <Redirect from='/procurement' to='/procurement/state/plan'/>
                    <Redirect from='/procurement/state' to='/procurement/state/plan'/>

                    <Redirect from='/general' to='/general/message'/>

                    <Redirect from='/misc' to='/misc/link'/>

                    <Redirect from='/respondent' to='/respondent/for_respondents'/>

                    <Redirect from='/region' to='/region/list'/>

                    <Redirect from='/for_users/cluster' to='/for_users/cluster/intro'/>
                    <Redirect from='/for_users/national' to='/for_users/national/2020'/>
                    <Redirect from='/for_users/economic' to='/for_users/economic/food'/>


                    <Redirect from='/official' to={`/official/industry/${firstIndustry}/statistic/6`}/>
                    <Redirect from='/official/industry' to={`/official/industry/${firstIndustry}/statistic/6`}/>
                    <Redirect from={`/official/industry/${firstIndustry}`}
                        to={`/official/industry/${firstIndustry}/statistic/6`}/>
                    <Redirect from={`/official/industry/${firstIndustry}/statistic`}
                        to={`/official/industry/${firstIndustry}/statistic/6`}/>

                    <Redirect from='/inf' to='/inf/standard/report'/>
                    <Redirect from='/inf/standard' to='/inf/standard/report'/>


                    <Redirect from='/edition' to='/edition/publication/month'/>
                    <Redirect from='/edition/publication' to='/edition/publication/month'/>

                    <Redirect from='/juridical' to='/juridical/list'/>
                    <Redirect from='/jurlist' to='/juridical/list'/>
                    <Redirect from='/jur-search' to='/jur-search/bin'/>

                    <Redirect from='/itis' to='/itis/interesting/know'/>
                    <Redirect from='/itis/interesting' to='/itis/interesting/know'/>

                    <Redirect from='/allCounter' to='/counter'/>

                    <Redirect from='/feedback' to='/feedback/28'/>

                    <Redirect from='*' to='/'/>
                </Switch>
            </Suspense>
        </div>
    )
};

export default withRouter(MainContainer);
