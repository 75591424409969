import React from 'react';

import CenterBlockTop from './CenterBlockTop';
import CenterBlockMiddle1 from './CenterBlockMiddle1';
import CenterBlockMiddle2 from './CenterBlockMiddle2';
import CenterBlockBottom from './CenterBlockBottom';

export default () =>
    <div className="footer-column">
        <CenterBlockTop/>
        <CenterBlockMiddle1/>
        <CenterBlockMiddle2/>
        <CenterBlockBottom/>
    </div>
