import React, {useContext} from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Link} from 'react-router-dom';
import {MenuContext} from "../../../contexts/menu/MenuContext";

const messages = defineMessages({
    contactsMsg: {
        id: "about.contactsMsg",
        defaultMessage: "Байланысу ақпараты"
    },
});

const Links = () => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const { dispatch } = useContext(MenuContext);

    function onMenuClickHandle() {
        dispatch({ type: "setActiveMenuItem", payload: "null" })
    }

return (
        <ul>
            <li>
                <Link to="/about/general/contact" onClick={onMenuClickHandle}>{formatMessage(messages.contactsMsg)}</Link>
            </li>
        </ul>
    )
};

export default () => (
    <div className="submenu-column">
        <Links/>
    </div>
);
