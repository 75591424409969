import React from 'react';

import Fetch from "../../common/Fetch";

export default () =>
    <div className="footer-block-body">
        <Fetch bundleKey="Tech_support.str1" />
        <br/>
        <Fetch bundleKey="Tech_support.str2" />
        <br/>
        <Fetch bundleKey="Tech_support.str3" />
        <br/>
        <Fetch bundleKey="Tech_support.str4" />
        <br/>
        <Fetch bundleKey="Tech_support.str5" />
    </div>
