import React, {useContext} from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Link} from 'react-router-dom';

import {MenuContext} from "../../contexts/menu/MenuContext";

import MenuSearch from './MenuSearch';

const messages = defineMessages({
    mainPage: {
        id: "header.mainPage",
        defaultMessage: "Негізгі"
    },
    aboutCompany: {
        id: "header.aboutCompany",
        defaultMessage: "Комитет жайлы"
    },
    regions: {
        id: "header.regions",
        defaultMessage: "Өңірлер"
    },
    offStat: {
        id: "header.offStat",
        defaultMessage: "Ресми статистика"
    },
    forRespondents: {
        id: "header.forRespondents",
        defaultMessage: "Респонденттерге"
    },
    forUsers: {
        id: "header.forUsers",
        defaultMessage: "Пайдаланушыларға"
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const { activeMenuItem, dispatch } = useContext(MenuContext);

    return (
        <div className="menu-row">
            <ul className="menu-left">
                <li>
                    <Link to="/" onMouseOver={() => dispatch({ type: "setActiveMenuItem", payload: "main" })}>
                        {formatMessage(messages.mainPage)}
                    </Link>
                </li>
                <li>
                    <button className={activeMenuItem === "about" ? "active" : ""}
                       onMouseOver={() => dispatch({ type: "setActiveMenuItem", payload: "about" })}>
                        {formatMessage(messages.aboutCompany)}
                    </button>
                </li>
                <li>
                    <button className={activeMenuItem === "region" ? "active" : ""}
                       onMouseOver={() => dispatch({ type: "setActiveMenuItem", payload: "region" })}>
                        {formatMessage(messages.regions)}
                    </button>
                </li>
                <li>
                    <button className={activeMenuItem === "offStat" ? "active" : ""}
                       onMouseOver={() => dispatch({ type: "setActiveMenuItem", payload: "offStat" })}>
                        {formatMessage(messages.offStat)}
                    </button>
                </li>
                <li>
                    <button className={activeMenuItem === "forResp" ? "active" : ""}
                       onMouseOver={() => dispatch({ type: "setActiveMenuItem", payload: "forResp" })}>
                        {formatMessage(messages.forRespondents)}
                    </button>
                </li>
                <li>
                    <button className={activeMenuItem === "forUser" ? "active" : ""}
                       onMouseOver={() => dispatch({ type: "setActiveMenuItem", payload: "forUser" })}>
                        {formatMessage(messages.forUsers)}
                    </button>
                </li>
            </ul>

            <MenuSearch/>
        </div>
    )
};
