import React, {useContext} from 'react';

import {MenuContext} from "../../contexts/menu/MenuContext";

import Menu from '../menu/Menu';
import About from "../menu/about/About";
import Regions from "../menu/regions/Regions"
import Statistics from "../menu/statistics/Statistics"
import Search from "../menu/search/Search";
import Respondent from "../menu/respondent/Respondent";
import User from "../menu/user/User";

export default () => {
    const { activeMenuItem, dispatch } = useContext(MenuContext);

    return (
        <div onMouseLeave={() => {
            // if (activeMenuItem !== "search") {
                dispatch({ type: "setActiveMenuItem", payload: null })
            // }
        }}>
            <Menu/>
            {activeMenuItem === "about" && (
                <About/>
            )}
            {activeMenuItem === "region" && (
                <Regions/>
            )}
            {activeMenuItem === "offStat" && (
                <Statistics/>
            )}
            {activeMenuItem === "forResp" && (
                <Respondent/>
            )}
            {activeMenuItem === "forUser" && (
                <User/>
            )}
            {activeMenuItem === "search" && (
                <Search/>
            )}
        </div>
    )
};
