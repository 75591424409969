import React, {useContext} from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Link} from 'react-router-dom';

import Fetch from "../../common/Fetch";

import {GeneralContext} from "../../../contexts/general/GeneralContext";

const messages = defineMessages({
    miscsHelpDoc: {
        id: "miscs.miscsHelpDoc",
        defaultMessage: "ESTAT218711"
    },
    grammarService: {
        id: "miscs.grammarService",
        defaultMessage: "Емле қателерін жіберу қызметі"
    },
    sendEmail: {
        id: "header.sendEmail",
        defaultMessage: "Хат жөнелту"
    },
    miscsHelpMsg: {
        id: "miscs.miscsHelpMsg",
        defaultMessage: "Анықтама"
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const { generalConfig } = useContext(GeneralContext);

    const urlMailTo = generalConfig ? 'mailto:' + generalConfig.kazStatRkMail : 'mailto:';

    const urlHelp = "api/getFile/?docId=" + formatMessage(messages.miscsHelpDoc);

    return (
        <div className="footer-block-body">
            <ul>
                {/*<li>*/}
                {/*    <Link to='/misc/link'><Fetch bundleKey="navigation.page.main.usefulllinks" /></Link>*/}
                {/*</li>*/}
                <li>
                    <Link to='/misc/subscribe'><Fetch bundleKey="navigation.page.privateroom.mailing" /></Link>
                </li>
                <li>
                    <a href={urlMailTo}>{formatMessage(messages.sendEmail)}</a>
                </li>
                <li>
                    <a href={urlHelp}>{formatMessage(messages.miscsHelpMsg)}</a>
                </li>
            </ul>
        </div>
    )
};
