import React from 'react';

import FooterLeft from './left/FooterLeft';
import FooterCenter from './center/FooterCenter';
import FooterRight from './right/FooterRight';

export default () =>
    <div className="footer-row">
        <FooterLeft/>
        <FooterCenter/>
        <FooterRight/>
    </div>
