import React from 'react';

export default () =>
    <span id="_zero_64741">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="http://zero.kz/?s=64741" target="_blank">
            <img width="88" height="31" alt="ZERO.KZ"
                 src="//c.zero.kz/z.png?u=64741&amp;t=1&amp;cc=z5d6ce06b248e7&amp;s=5d8de482032b6&amp;sh=1&amp;slt=262972&amp;sls=5d89e14665349&amp;d=0&amp;wd=1920&amp;hg=1080&amp;cd=24&amp;w=1903&amp;h=937&amp;ln=ru&amp;je=0&amp;cs=UTF-8&amp;ce=1&amp;du=http%3A%2F%2Fold.stat.gov.kz%2Ffaces%2FhomePage%3Flang%3Dru%26_afrLoop%3D1282076620457581%23%2540%253F_afrLoop%253D1282076620457581%2526lang%253Dru%2526_adf.ctrl-state%253Db84eqmerr_25&amp;rf=http%3A%2F%2Fold.stat.gov.kz%2Ffaces%2FhomePage%3Bjsessionid%3DiCFyQ5mURognHKfF-W4wUXkxIepamA7JbHIeU-hGE32285dQUbdY!893045835!130536340%3Flang%3Dru%26_afrLoop%3D1282045590973622&amp;tz=-360&amp;dt=%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F&amp;9.506855074565195" />
            </a>
        </span>
