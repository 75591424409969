import React, {useState} from 'react';
import Spinner from "./Spinner";

const hideSpinner = (setIsLoading) => {
    setIsLoading(false);
};

export default props => {
    const { frameUrl, frameHeight } = props;
    const frameStyle = props.frameStyle || null;

    const [isLoading, setIsLoading] = useState(true);

    return (
        <div className={frameStyle}>
            {isLoading && (
                <Spinner size='lg' spinning='spinning' color="#10a4fb" />
            )}
            <iframe title={frameUrl} src={frameUrl} frameBorder="0" height={frameHeight} width="100%"
                    onLoad={() => hideSpinner(setIsLoading)}/>
        </div>
    )
};

