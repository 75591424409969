import React, {useContext} from 'react';
import {defineMessages, useIntl} from "react-intl";

import {GeneralContext} from "../../contexts/general/GeneralContext";

import eye from "../../images/eye.png";
import mobilePng from "../../images/mobile.png";

const messages = defineMessages({
    oldVersion: {
        id: 'header.oldVersion',
        defaultMessage: 'Ескі нұсқа'
    },
    visualImpaired: {
        id: 'header.visualImpaired',
        defaultMessage: 'Нашар көретіндерге арналған нұсқа'
    },
    mobileVersion: {
        id: 'header.mobileVersion',
        defaultMessage: 'Мобильді нұсқа'
    },
    usualVersion: {
        id: 'header.usualVersion',
        defaultMessage: 'Қалыпты нұсқа'
    },
    fullVersion: {
        id: 'header.fullVersion',
        defaultMessage: 'Толық нұсқа'
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const { isFullVersion, dispatch } =
        useContext(GeneralContext);


    return (
        <div className="header-bottom-menu">
            <ul>
                <li className="fullVersion">
                    <button onClick={() =>
                        dispatch({ type: "setIsFullVersion", payload: !isFullVersion })
                    }>
                        {
                            isFullVersion
                                ? <img className="icon-mobile" src={mobilePng}
                                       title={formatMessage(messages.mobileVersion)}
                                       alt={formatMessage(messages.mobileVersion)}/>
                                : formatMessage(messages.fullVersion)
                        }
                    </button>
                </li>
            </ul>
        </div>
    )
};
