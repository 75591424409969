import React, {useContext} from 'react';

import {MenuContext} from "../../../contexts/menu/MenuContext";

import close from "../../../images/close.png";

export default () => {
    const { dispatch } = useContext(MenuContext);

    return (
        <div className="search-right">
            <button onClick={() => dispatch({ type: "setActiveMenuItem", payload: "null" })}>
                <img src={close} alt="close"/>
            </button>
        </div>
    );
};
