import React from 'react';

import LeftBlockTopInfo from "./LeftBlockTopInfo";
import Fetch from "../../common/Fetch";

export default () =>
    <div className="footer-block">
        <h4>
            <Fetch bundleKey="main.ContactInfo" />
        </h4>
        <LeftBlockTopInfo/>
    </div>
