import React from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Link} from 'react-router-dom';

import logo from "../../images/logo.png";

const messages = defineMessages({
    mainPage: {
        id: "header.mainPage",
        defaultMessage: "Негізгі"
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <div className="logo">
            <nav>
                <Link to='/'>
                    <img src={logo}
                         title={formatMessage(messages.mainPage)}
                         alt={formatMessage(messages.mainPage)}/>
                </Link>
            </nav>
        </div>
    );
}
