import React, {useContext, useEffect, useState} from 'react';
import Axios from "axios/index";

import Spinner from "./Spinner";

import {GeneralContext} from "../../contexts/general/GeneralContext";

const fetchData = (bundleKey, langParam, setResValue, setLoading) => {
    const urlRes = "api/bundle/?" + langParam + '&key=' + bundleKey;

    Axios.get(urlRes)
        .then(response => {

            setResValue(response.data.resourceValue);
        })
        .then(() => {
            setLoading(false);
        })
        .catch((error) => {
            console.error("Fetch: " + urlRes, error)
        });
};

export default props => {
    const { bundleKey } = props;

    const { locale, conf: { url: { prmLang } } } = useContext(GeneralContext);
    const langParam = prmLang + locale;

    const [resValue, setResValue] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData(bundleKey, langParam, setResValue, setLoading);
    }, [bundleKey, langParam]);

    if (loading) {
        return <Spinner size='lg' spinning='spinning' />
    }

    return (
        <span>{resValue}</span>
    );
};
