import React, {useState} from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Redirect} from "react-router-dom";

const messages = defineMessages({
    typeSearchText: {
        id: "header.typeSearchText",
        defaultMessage: "Іздеу үшін мәтінді теріңіз..."
    },
    siteSearch: {
        id: "header.siteSearch",
        defaultMessage: "Іздеу"
    }
});

const handleChange = (ev, setSearchTxt) => {
    ev.preventDefault();

    setSearchTxt(ev.target.value);
};

const handleSubmit = (e, setFireRedirect) => {
    e.preventDefault();

    setFireRedirect(true);
};

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const [searchTxt, setSearchTxt] = useState("");
    const [fireRedirect, setFireRedirect] = useState(false);

    return (
        <div className="search-left">
            <form onSubmit={(e) => {
                if (searchTxt) {
                    handleSubmit(e, setFireRedirect);
                }
            }}>
                <input type="text"
                       name="searchTxt"
                       maxLength="200"
                       placeholder={formatMessage(messages.typeSearchText)}
                       value={searchTxt}
                       onChange={(ev) => handleChange(ev, setSearchTxt)}
                />
                <button type="submit">{formatMessage(messages.siteSearch)}</button>
            </form>

            {fireRedirect && (
                <Redirect to={'/search/' + searchTxt}/>
            )}
        </div>
    )
};
