import React, {useContext} from 'react';
import {defineMessages, useIntl} from "react-intl";

import {GeneralContext} from "../../contexts/general/GeneralContext";

import eye from "../../images/eye.png";
import mobilePng from "../../images/mobile.png";

const messages = defineMessages({
    oldVersion: {
        id: 'header.oldVersion',
        defaultMessage: 'Ескі нұсқа'
    },
    visualImpaired: {
        id: 'header.visualImpaired',
        defaultMessage: 'Нашар көретіндерге арналған нұсқа'
    },
    mobileVersion: {
        id: 'header.mobileVersion',
        defaultMessage: 'Мобильді нұсқа'
    },
    usualVersion: {
        id: 'header.usualVersion',
        defaultMessage: 'Қалыпты нұсқа'
    },
    fullVersion: {
        id: 'header.fullVersion',
        defaultMessage: 'Толық нұсқа'
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const {isPoorVision, isMobileVersion, isFullVersion, dispatch } =
        useContext(GeneralContext);

    return (
        <div className="header-top-menu">
            <ul>
                <li className="poorVision">
                    <button onClick={() =>
                        dispatch({ type: "setIsPoorVision", payload: !isPoorVision })
                    }>
                        {isPoorVision ?
                            formatMessage(messages.usualVersion) :
                            formatMessage(messages.visualImpaired)
                        }
                        {!isPoorVision && (
                            <img className="icon-eye" src={eye}
                                 alt={formatMessage(messages.visualImpaired)}/>
                        )}
                    </button>
                </li>

                <li className="mobileVersion">
                    <button onClick={() =>
                        dispatch({ type: "setIsMobileVersion", payload: !isMobileVersion })
                    }>
                        <img className="icon-mobile" src={mobilePng}
                             title={formatMessage(messages.mobileVersion)}
                             alt={formatMessage(messages.mobileVersion)}/>

                    </button>
                </li>

                <li className="fullVersion">
                    <button onClick={() =>
                        dispatch({ type: "setIsFullVersion", payload: !isFullVersion })
                    }>
                        {
                            isFullVersion
                                ? <img className="icon-mobile" src={mobilePng}
                                       title={formatMessage(messages.mobileVersion)}
                                       alt={formatMessage(messages.mobileVersion)}/>
                                : formatMessage(messages.fullVersion)
                        }
                    </button>
                </li>
            </ul>
        </div>
    )
};
