// eslint-disable-next-line no-unused-vars
import React, {useMemo, useReducer, useState} from 'react';

import Axios from "axios/index";

import conf from "../../conf.json";
import region from "../../i18n/region";

let locale='kk';
let query_string = {};

const query = window.location.search.slice(1);
if (query !== "") {

    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {

        let pair = vars[i].split("=");
        if (typeof query_string[pair[0]] === "undefined") {

            query_string[pair[0]] = decodeURIComponent(pair[1]);
        } else if (typeof query_string[pair[0]] === "string") {

            query_string[pair[0]] = [query_string[pair[0]], decodeURIComponent(pair[1])];
        } else {

            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }

    if (query_string.lang) {
        locale = query_string.lang;
    }

    if (query_string.mourning) {
        document.body.classList.add('mourning');
    }
}

const getLocales = (setLocales) => {
    Axios.get("api/locales")
        .then(response => {
            setLocales(response.data);
        })
        .catch((error) => {
            console.log("api/locales", error)
        });
};

const getGeneralConfig = (setGeneralConfig) => {
    Axios.get("api/general/get")
        .then(response => {
            const data = response.data;

            setGeneralConfig(data);

            if (data.mourning) {
                document.body.classList.add('mourning');
            }
        })
        .catch((error) => {
            console.log("api/general/get", error)
        });
};

const getConfig = (setGeneral) => {
    Axios.get("api/config")
        .then(response => {
            setGeneral(response.data);
        })
        .catch((error) => {
            console.log("api/config", error)
        });
};

const setIsPoorVision = (isPoorVision) => {
    if (isPoorVision) {
        document.body.classList.add('poor-vision');
    } else {
        document.body.classList.remove('poor-vision');
    }
};

const setIsMobileVersion = (isMobileVersion) => {
    if (isMobileVersion) {
        document.body.classList.add('mobile-vision');
    }
    else {
        document.body.classList.remove('mobile-vision');
    }
};

const setIsFullVersion = (isFullVersion) => {
    if (isFullVersion) {
        document.body.classList.add('full-version');
        document.querySelector("link[id='screen']").disabled = true;
    }
    else {
        document.body.classList.remove('full-version');
        document.querySelector("link[id='screen']").disabled = false;
    }
};

const initialState = {
    conf: conf,
    isPoorVision: false,
    setPoorVision: setIsPoorVision(false),

    isMobileVersion:false,
    setMobileVersion: setIsMobileVersion(false),

    isFullVersion:false,
    setFullVersion: setIsFullVersion(false),
};

export const GeneralContext = React.createContext(initialState);

export const GeneralContextProvider = ({ children }) => {
    const [general, setGeneral] = useState({});
    const [generalConfig, setGeneralConfig] = useState({});
    const [locales, setLocales] = useState({});

    useMemo(() => {
        getConfig(setGeneral);
        getGeneralConfig(setGeneralConfig);
        getLocales(setLocales);
    }, []);

    initialState["general"] = general;
    initialState["general"]["mainNewsCount"] = 5;
    initialState["general"]["urlBase"] = "api/getFile/?docId=";
    initialState["generalConfig"] = generalConfig;
    initialState["locales"] = locales;

    if (!locale && generalConfig.defaultLocale) {
        locale = generalConfig.defaultLocale.localeKey;
    }

    initialState["locale"] = locale;
    initialState["regions"] = region[locale];
    //initialState["generalConfig"]["knowWhatLimit"] = 9;

    const reducer = (state, action) => {
        switch (action.type) {

            case "setIsPoorVision":
                const { isPoorVision, setPoorVision, ...restOfThem } = state;
                return {
                    isPoorVision: action.payload,
                    setPoorVision: setIsPoorVision(action.payload),
                    ...restOfThem
                };

            case "setIsMobileVersion":
                const { isMobileVersion, setMobileVersion, ...other } = state;
                return {
                    isMobileVersion: action.payload,
                    setMobileVision: setIsMobileVersion(action.payload),
                    ...other
                };

            case "setIsFullVersion":
                const { isFullVersion, setFullVersion, ...restFullVersion } = state;
                return {
                    isFullVersion: action.payload,
                    setFullVision: setIsFullVersion(action.payload),
                    ...restFullVersion
                };

            case "setLocale":
                const { locale, regions, ...restExceptLocale } = state;
                const { payload } = action;

                return {
                    locale: payload,
                    regions: region[payload],
                    ...restExceptLocale
                };

            default:
                return {...state};
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <GeneralContext.Provider value={{ ...state, dispatch }}>
            {children}
        </GeneralContext.Provider>
    );
};
