import React from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import search from "../../images/search.png";

// import {MenuContext} from "../../contexts/menu/MenuContext";

const messages = defineMessages({
    siteSearch: {
        id: "header.siteSearch",
        defaultMessage: "Іздеу"
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    // const { activeMenuItem, dispatch } = useContext(MenuContext);

    return (
        <div className="menu-right">
            {/*<button className={activeMenuItem === "search" ? "active" : ""}
                    onMouseOver={() => dispatch({ type: "setActiveMenuItem", payload: "search" })}>
                <img src={search}
                     title={formatMessage(messages.siteSearch)}
                     alt={formatMessage(messages.siteSearch)}/>
            </button>*/}
            <Link to='/search'>
                <img src={search}
                     title={formatMessage(messages.siteSearch)}
                     alt={formatMessage(messages.siteSearch)}/>
            </Link>
        </div>
    );
};
