import React from 'react';

import Fetch from "../../common/Fetch";

export default () =>
    <div className="footer-block-body">
        <Fetch bundleKey="main.ContactCallcentre.str0" />
        <br/>
        <Fetch bundleKey="main.ContactCallcentre.str1" />
        <br/>
        <Fetch bundleKey="main.ContactCallcentre.str2" />
    </div>
