import React from 'react';

import RightBlockTop from './RightBlockTop';
import RightBlockBottom from "./RightBlockBottom";
import RightBlockSocialIcons from "./RightBlockSocialIcons";
import GetMistakes from "../../miscellaneous/GetMistakes";

export default () =>
    <div className="footer-column">
        <RightBlockTop/>
        <RightBlockBottom/>
        <GetMistakes />
        <RightBlockSocialIcons/>
    </div>
