import React, {useContext} from 'react';

import DocumentFrame from '../common/DocumentFrame';

import {GeneralContext} from "../../contexts/general/GeneralContext";

export default () => {
    const { generalConfig, locale } = useContext(GeneralContext);
    // const urlPrimierUrl = (generalConfig ? generalConfig.primeMinisterBegURL : '') +
    //     (locale === 'kk' ? 'kz' : locale === 'qq' ? 'kz' : locale) +
    //     (generalConfig ? generalConfig.primeMinisterEndURL : '');


    let urlPrimierUrl = 'https://www.akorda.kz/ru/widge';

    switch (locale) {
        case 'kk':
            urlPrimierUrl='https://www.akorda.kz/kz/widget';
            break;
        case 'qq':
            urlPrimierUrl='https://www.akorda.kz/kz/widget';
            break;
        case 'en':
            urlPrimierUrl='https://www.akorda.kz/en/widget';
            break;
        default:
            urlPrimierUrl='https://www.akorda.kz/ru/widget'
    }

    return (
        <div className="premier-block">
            <DocumentFrame frameUrl={urlPrimierUrl}
                           frameHeight="325"
                           frameStyle="container"
            />
        </div>
)};
