import React from 'react';

import LeftBlockBottomInfo from "./LeftBlockBottomInfo";
import Fetch from "../../common/Fetch";

export default () =>
    <div className="footer-block">
        <h4>
            <Fetch bundleKey="main.ContactPressService" />
        </h4>
        <LeftBlockBottomInfo/>
    </div>