import React, {useContext} from 'react';
import {IntlProvider} from "react-intl";

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/kk';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ru';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/qq';

import translations from "./i18n/locales";

import App from "./App";

import {GeneralContext} from "./contexts/general/GeneralContext";

export default () => {
    const { locale } = useContext(GeneralContext);
    const messages = translations[locale];

    return (
        //<div className="grayscale"> {/*div было добавлено для принудительного траурного серого цвета, как закончится траурный день - !убрать!*/}
        <IntlProvider locale={locale} key={locale} messages={messages} defaultLocale={locale}>
            <App/>
        </IntlProvider>
        //</div>
    );
};
