import React, {useContext} from 'react';

import {GeneralContext} from "../../contexts/general/GeneralContext";

export default () => {
    const { locales, locale, dispatch } = useContext(GeneralContext);

    return (
        <div className="header-lang-menu">
            {(locales && locales.length > 0) && (
                <ul>
                    {locales.map(value =>
                        <li key={value.localeKey}>
                            <button className={locale === value.localeKey ? "active" : ""}
                                    onClick={() =>
                                        dispatch({ type: "setLocale", payload: value.localeKey })
                                    }>
                                {value.shortName}
                            </button>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};
