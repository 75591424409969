import React from 'react';

import LeftBlockTop from './LeftBlockTop';
import LeftBlockBottom from "./LeftBlockBottom";

export default () =>
    <div className="footer-column">
        <LeftBlockTop/>
        <LeftBlockBottom/>
    </div>
