import React from 'react';
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
    title01: {
        id: 'header.title01',
        defaultMessage: 'Қазақстан Республикасы'
    },
    title02: {
        id: 'header.title02',
        defaultMessage: 'Стратегиялық жоспарлау'
    },
    title03: {
        id: 'header.title03',
        defaultMessage: 'және реформалар агенттігі'
    },
    title04: {
        id: 'header.title04',
        defaultMessage: 'Ұлттық статистика бюросы'
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <div className="title">
            <p>{formatMessage(messages.title01)}</p>
            <p>{formatMessage(messages.title02)}</p>
            <p>{formatMessage(messages.title03)}</p>
            <p>{formatMessage(messages.title04)}</p>
        </div>
    )
};
