import React from 'react';
import {defineMessages, useIntl} from "react-intl";

import RightBlockBottomInfo from "./RightBlockBottomInfo";

const messages = defineMessages({
    miscellaneous: {
        id: "footer.miscellaneous",
        defaultMessage: "Басқа"
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <div className="footer-block">
            <h4>{formatMessage(messages.miscellaneous)}</h4>
            <RightBlockBottomInfo/>
        </div>
    )
};
