import React, {useContext} from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Link} from 'react-router-dom';

import {GeneralContext} from "../../../contexts/general/GeneralContext";
import {MenuContext} from "../../../contexts/menu/MenuContext";

const messages = defineMessages({
    dynamic: {
        id: "main.dymnamic",
        defaultMessage: "Негізгі әлеуметтік-экономикалық көрсеткiштердің серпіні"
    },

    standardsOfImf: {
        id: "header.standardsOfImf",
        defaultMessage: "ХВҚ стандарттары"
    },
    survey: {
        id: "cluster.survey",
        defaultMessage: "Мульти-индикаторлық кластерлік зерттеу"
    },
    nationalMsg: {
        id: "census.nationalMsg",
        defaultMessage: "Ұлттық санақтары"
    },
    economicMsg: {
        id: "monitoring.economicMsg",
        defaultMessage: "Мемлекеттік органдар үшін экономикалық көрсеткіштер мониторингі"
    },
    sustainableDevelopmentGoalsMsg: {
        id: "sustainableDevelopmentGoals.monitoringMsg",
        defaultMessage: "2030 жылға дейінгі Тұрақты даму мақсаттарының мониторингі"
    },
    interestingMsg: {
        id: "itis.interestingMsg",
        defaultMessage: "Бұл қызықты"
    },
    genderStatisticsMsg: {
        id: "header.genderStatisticsMsg",
        defaultMessage: "Гендерлік статистика"
    },
    childrenStatisticsMsg: {
        id: "header.childrenStatisticsMsg",
        defaultMessage: "Балалар туралы статистик"
    },
    jurlistMsg: {
        id: "main.statisticalBusinessRegisterMsg",
        defaultMessage: "Статистикалық бизнес-тіркелім"
    },
    indicatorMsg: {
        id: "official.indicatorMsg",
        defaultMessage: "Мемлекеттік және үкіметтік бағдарламалардың көрсеткіштері"
    },
    ecologicIndicatorMsg: {
        id: "official.ecologicIndicatorMsg",
        defaultMessage: "Қоршаған ортаны бағалау және мониторингілеудің экологиялық индикаторлары"
    },
    economicQuality: {
        id: "monitoring.economicQualityMsg",
        defaultMessage: "Сапа туралы есептер"
    },
    newTabMsg: {
        id: "main.newTabMsg",
        defaultMessage: "Бұл сілтеме жаңа терезеде (салымында) ашылады. Жалғастырғыңыз келе ме?"
    }
});

const Links = () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const { generalConfig, locale } = useContext(GeneralContext);
    const genderUrl = generalConfig ? generalConfig.genderStatisticsURL : '' + locale;
    //@TODO: добавить локализацию при переходе, kk->kz
    // const childrenUrl = '//bala.stat.gov.kz/' + locale;
    const childrenUrl = '//bala.stat.gov.kz/';

    const { dispatch } = useContext(MenuContext);

    function onMenuClickHandle() {
        dispatch({ type: "setActiveMenuItem", payload: "null" })
    }

    return (
        <ul>
            <li>
                <Link to="/for_users/dynamic" onClick={onMenuClickHandle}>{formatMessage(messages.dynamic)}</Link>
            </li>
            <li>
                <Link to="/for_users/standard/report" onClick={onMenuClickHandle}>{formatMessage(messages.standardsOfImf)}</Link>
            </li>
            <li>
                <Link to="/for_users/cluster/intro" onClick={onMenuClickHandle}>{formatMessage(messages.survey)}</Link>
            </li>
            <li>
                <Link to="/for_users/national/2020" onClick={onMenuClickHandle}>{formatMessage(messages.nationalMsg)}</Link>
            </li>
            <li>
                <Link to="/for_users/economic/monitoring_agglomerations" onClick={onMenuClickHandle}>{formatMessage(messages.economicMsg)}</Link>
            </li>
            <li>
                <Link to="/for_users/sustainable_development_goals" onClick={onMenuClickHandle}>{formatMessage(messages.sustainableDevelopmentGoalsMsg)}</Link>
            </li>
            <li>
                <div className="href-button">
                    <button onClick={() =>
                    {
                        onMenuClickHandle();
                        window.confirm(formatMessage(messages.newTabMsg)) &&
                        window.open(genderUrl)
                    }
                    }>
                        <span>{formatMessage(messages.genderStatisticsMsg)}</span>
                    </button>
                </div>
            </li>

            <li>
                <div className="href-button">
                    <button onClick={() =>
                    {
                        onMenuClickHandle();
                        window.confirm(formatMessage(messages.newTabMsg)) &&
                        window.open(childrenUrl)
                    }
                    }>
                        <span>{formatMessage(messages.childrenStatisticsMsg)}</span>
                    </button>
                </div>
            </li>
            {/*<li>*/}
            {/*    <Link to="/for_users/indicator" onClick={onMenuClickHandle}>{formatMessage(messages.indicatorMsg)}</Link>*/}
            {/*</li>*/}
            <li>
                <Link to="/for_users/ecologic_indicators/ecologic_indicator" onClick={onMenuClickHandle}>{formatMessage(messages.ecologicIndicatorMsg)}</Link>
            </li>

            <li>
                <Link to="/for_users/economic_quality" onClick={onMenuClickHandle}>{formatMessage(messages.economicQuality)}</Link>
            </li>
        </ul>
    )
};

export default () => (
    <div className="submenu-column">
        <Links/>
    </div>
);
