import React, {useContext} from 'react';
import {defineMessages, useIntl} from "react-intl";
import {Link} from 'react-router-dom';

import {GeneralContext} from "../../../contexts/general/GeneralContext";
import {MenuContext} from "../../../contexts/menu/MenuContext";

const messages = defineMessages({
    cabinet: {
        id: "main.cabinet",
        defaultMessage: "Респонденттің кабинеті"
    },
    respCatalogDoc: {
        id: "resp.respCatalogDoc",
        defaultMessage: "ESTAT187917"
    },
    respFormMsg: {
        id: "resp.respFormMsg",
        defaultMessage: "2018 жылғы статистикалық нысандар"
    },
    respArchiveMsg: {
        id: "resp.respArchiveMsg",
        defaultMessage: "Статистикалық нысандар мұрағаты"
    },
    respCatalogMsg: {
        id: "resp.respCatalogMsg",
        defaultMessage: "Мемлекеттік органдарда қалыптастырылған есептіліктің бірыңғай тізілімі"
    },
    respSoftwareMsg: {
        id: "resp.respSoftwareMsg",
        defaultMessage: "Электрондық шаруашылық бойынша есепке алуды жүргізуге арналған бағдарламалық қамтамасыз ету"
    },
    newTabMsg: {
        id: "main.newTabMsg",
        defaultMessage: "Бұл сілтеме жаңа терезеде (салымында) ашылады. Жалғастырғыңыз келе ме?"
    },
    forRespondentsMsg: {
        id: "header.forRespondents",
        defaultMessage: "Респонденттерге"
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const { generalConfig, locale, conf: { url } } = useContext(GeneralContext);

    const urlCabinet = (generalConfig ? generalConfig.cabinetURL : '') + '?' + url.prmLang + locale;

    const { dispatch } = useContext(MenuContext);

    function onMenuClickHandle() {
        dispatch({ type: "setActiveMenuItem", payload: "null" })
    }

    return (
        <div className="submenu-column">
            <ul>
                <li>
                    <Link to="/respondent/for_respondents" onClick={onMenuClickHandle}>{formatMessage(messages.forRespondentsMsg)}</Link>
                </li>
                <li>
                    <div className="href-button">
                        <button onClick={() =>
                            {
                                onMenuClickHandle();
                                window.confirm(formatMessage(messages.newTabMsg)) &&
                                window.open(urlCabinet)
                            }
                        }>
                            <span>{formatMessage(messages.cabinet)}</span>
                        </button>
                    </div>
                    {/*<a href={urlCabinet} target="_blank" rel="noopener noreferrer">
                        {formatMessage(messages.cabinet)}
                    </a>*/}
                </li>
                <li>
                    <Link to="/respondent/form" onClick={onMenuClickHandle}>{formatMessage(messages.respFormMsg)}</Link>
                </li>
                <li>
                    <Link to="/respondent/archive" onClick={onMenuClickHandle}>{formatMessage(messages.respArchiveMsg)}</Link>
                </li>
                <li>
                    <Link to="/respondent/catalog" onClick={onMenuClickHandle}>{formatMessage(messages.respCatalogMsg)}</Link>
                </li>
                <li>
                    <Link to="/respondent/software" onClick={onMenuClickHandle}>{formatMessage(messages.respSoftwareMsg)}</Link>
                </li>
            </ul>
        </div>
    );
};
