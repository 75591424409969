import React from 'react';
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
    ministryName: {
        id: "siteFooter.ministryName",
        defaultMessage: "Қазақстан Республикасы Ұлттық экономика министрлігі"
    }
});

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <div>
            {formatMessage(messages.ministryName)}
        </div>
    )
};
