import React, {useContext, useEffect, useState} from 'react';
import {defineMessages, useIntl} from "react-intl";
import AliceCarousel from 'react-alice-carousel';

import Axios from "axios/index";

import "react-alice-carousel/lib/alice-carousel.css";

import Spinner from "../common/Spinner";

import {GeneralContext} from "../../contexts/general/GeneralContext";

const messages = defineMessages({
    newTabMsg: {
        id: "main.newTabMsg",
        defaultMessage: "Бұл сілтеме жаңа терезеде (салымында) ашылады. Жалғастырғыңыз келе ме?"
    }
});

const responsive = {
    0: {
        items: 1
    },
    1024: {
        items: 8
    }
};

const handleOnDragStart = e => e.preventDefault();

const getCarouselItem = (oldPortalURL, data, i, formatMessage) => {
    const alink = data.alink;
    const idUcm = data.idUcm;
    const title = data.title;

    if (alink === 'flash') {
        return (
            <div key={i}>
                <embed src={oldPortalURL + idUcm} height="90px" width="120px" type="application/x-shockwave-flash"
                       title={title} onDragStart={handleOnDragStart}/>
            </div>
        )
    } else {
        return (
            <div key={i}>
                <div className="href-button">
                    <button onClick={() =>
                        window.confirm(formatMessage(messages.newTabMsg)) &&
                        window.open(alink)
                    }>
                        <img alt={title} src={oldPortalURL + idUcm} height="90px" width="120px" title={title}
                             onDragStart={handleOnDragStart}/>
                    </button>
                </div>
                {/*<a href={alink} target={"_blank"}>
                    <img alt={title} src={oldPortalURL + idUcm} height="90px" width="120px" title={title}
                         onDragStart={handleOnDragStart}/>
                </a>*/}
            </div>
        )
    }
};

const fetchData = ({ url, locale, setBanners, setLoading }) => {
    const urlBanners = "api/banners/?" + url.prmLang + locale;

    Axios.get(urlBanners)
        .then(response => {

            setBanners(response.data);
        })
        .then(() => {
            setLoading(false);
        })
        .catch((error) => {
            console.error("CarouselRow: " + urlBanners, error)
        });
};

export default () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const { generalConfig, locale, conf: { url } } = useContext(GeneralContext);
    //const oldPortalURL = (generalConfig ? generalConfig.oldPortalURL : '') + '/';
    // const oldPortalURL = (generalConfig && generalConfig.oldPortalURL || '') + '/';

    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData({ setBanners, url, locale, setLoading });
    }, [locale, url]);

    if (loading) {
        return <Spinner size='lg' spinning='spinning' color="#10a4fb" />
    }

    //const bannerFolder =  process.env.PUBLIC_URL+'/sourceimage/';
    const bannerFolder =  '/';

    return (
        <div className="carousel-middle">
            {banners.length > 0 && (
                <AliceCarousel responsive={responsive} dotsDisabled={true} autoPlay={true} duration={1500}
                               buttonsDisabled={true} mouseDragEnabled={true}>
                    {banners.map((data, i) =>
                        getCarouselItem(bannerFolder, data, i, formatMessage)
                    )}
                </AliceCarousel>
            )}
        </div>
    )
};

